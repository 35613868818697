import React from 'react';
import {
  getLayoutColClass,
  getSpanClass,
  gatGapClass,
  getBgColor,
  renderComponents,
  getHeight,
} from '../utils/layoutHelpers';
import { Grid } from './twEssential';
import '../App.css';

export default function GridLayout(props) {
  const {
    data,
    actions,
    entity,
    extensionEntity,
    params,
    fields,
    query,
    closePopup,
    filterParams,
  } = props;
  const {
    anchor,
    bgColor,
    gap,
    span,
    height,
    allowOverflow,
    customGridHeight,
    customGridMaxHeight,
    components,
    additionalClasses,
    classNameSet,
  } = data;

  return (
    <Grid
      id={anchor}
      bgColor={getBgColor(bgColor)}
      gap={gatGapClass(gap)}
      column={getLayoutColClass(data)}
      span={getSpanClass(span)}
      height={getHeight(height)}
      className={`  ${additionalClasses ? additionalClasses : ''} ${
        allowOverflow ? 'overflow-y-auto overflow-x-hidden w-full scrollStyle' : ''
      }`}
      style={{
        height: `${allowOverflow && customGridHeight ? `${customGridHeight}` : ''}`,
        maxHeight: `${allowOverflow && customGridMaxHeight ? `${customGridMaxHeight}` : ''}`,
      }}
    >
      {renderComponents(
        components,
        actions,
        entity,
        extensionEntity,
        params,
        query,
        fields,
        closePopup,
        filterParams,
      )}
    </Grid>
  );
}
